import React from 'react';
import NavBar from '../Assets/NavBar/NavBar';
import Footer from '../Assets/Footer/Footer';
import { Link } from 'react-router-dom';

import './CancelStyling.css';

const Cancel = () => {
    return (
        <div>
            <NavBar gradientColors={['#d3446b', '#e76e54']} />
            <div className="cancel-container">
                <div className="cancel-content">
                    <h2>Order Cancelled...</h2>
                    <p>Sorry to see you go!</p>
                    <Link to="/" className="cancel-return-home">
                        Return Home
                    </Link>{' '}
                </div>
            </div>
            <div style={{ height: '400px' }}></div>
            <Footer />
        </div>
    );
};

export default Cancel;
