import React, { useState, useEffect } from 'react';
import './NavBarStyle.css';
import Logo from '../../../images/Logos/LogoWhite.png';
import { IoMenu, IoClose } from 'react-icons/io5';

interface NavBarProps {
    gradientColors: string[]; // Array containing gradient colors in hex format
}

const NavBar: React.FC<NavBarProps> = ({ gradientColors }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [rgbaGradientColors, setRgbaGradientColors] = useState<string[]>([]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 600);
        };

        const debouncedHandleResize = debounce(handleResize, 200); // Debounce resize event

        window.addEventListener('resize', debouncedHandleResize);

        handleResize(); // Call initially

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);

    useEffect(() => {
        const convertedColors = gradientColors.map((color) =>
            hexToRgba(color, 0.85)
        );
        setRgbaGradientColors(convertedColors);
    }, [gradientColors]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    const gradientString = `linear-gradient(to right, ${rgbaGradientColors.join(', ')})`;

    return (
        <>
            <nav className="navbar" style={{ backgroundImage: gradientString }}>
                <div className="navbar-container">
                    <NavbarLogo />
                    <div className="navbar-text">
                        {/* <a href="/" className="navbar-company-name">
                            Course Clutch
                        </a> */}
                    </div>
                    {isMobileView ? (
                        <button
                            className="sidebar-toggle"
                            onClick={toggleSidebar}
                            aria-expanded={isSidebarOpen ? 'true' : 'false'}
                            style={{ marginLeft: 'auto' }} // Align to the right
                        >
                            {isSidebarOpen ? <IoClose /> : <IoMenu />}
                        </button>
                    ) : (
                        <NavbarLinks />
                    )}
                </div>
            </nav>
            {isMobileView && isSidebarOpen && (
                <div className={`overlay`} onClick={closeSidebar}></div>
            )}
            {isMobileView && (
                <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                    <SidebarContent />
                </div>
            )}
        </>
    );
};

const NavbarLogo: React.FC = () => (
    <div className="navbar-logo">
        <a href="/">
            <img src={Logo} alt="Course Clutch Logo" />
        </a>
    </div>
);

const NavbarLinks: React.FC = () => (
    <div className="navbar-links">
        <a href="/" className="nav-link">
            Home
        </a>
        <a href="/waterloo" className="nav-link">
            Waterloo
        </a>
        <a href="/western" className="nav-link">
            Western
        </a>
        <a href="/about" className="nav-link">
            About
        </a>
    </div>
);

const SidebarContent: React.FC = () => (
    <div className="sidebar-links">
        <a href="/" className="sidebar-link">
            Home
        </a>
        <a href="/waterloo" className="sidebar-link">
            Waterloo
        </a>
        <a href="/western" className="sidebar-link">
            Western
        </a>
        <a href="/about" className="sidebar-link">
            About
        </a>
    </div>
);

function debounce(func: Function, wait: number) {
    let timeout: ReturnType<typeof setTimeout>;
    return function executedFunction(...args: any[]) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

// Function to convert hex color to RGBA with specified opacity
function hexToRgba(hex: string, opacity: number): string {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export default NavBar;
