import React, { useContext, useEffect, useState } from "react";
import NavBar from "../Assets/NavBar/NavBar";
import "./WesternStyle.css";
import SearchBar from "../Assets/SearchBar/SearchBar";
import bothLogos from "../../images/UniversityLogos/CourseClutchWestern.png";
import westernAllCourseList from "./westernAllCourseList.json";
import Sections from "../Assets/Sections/Western/Sections";
import Footer from "../Assets/Footer/Footer";
import axios from "axios";
import { ring } from "ldrs";
import EnvContext from "../ContextProviders/EnvContext";

ring.register();

interface SectionType {
  section: string;
  component: string;
  class_number: string;
  requisites: string;
  status: string;
  campus: string;
  delivery_type: string;
}

interface CourseSection {
  name: string;
  subject_name: string;
  code: string;
  description: string;
  antirequisites: string;
  extra_info: string;
  sections: SectionType[];
}

const gradient = ["#4F2683", "#8F55E0"];

const Western: React.FC = () => {
  const [clickedCourse, setClickedCourse] = useState<{
    name: string;
  } | null>(null);
  const [courseName, setCourseName] = useState<string>("");
  const [westernCourseSections, setWesternCourseSections] =
    useState<CourseSection | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [termCode, setTermCode] = useState<number>(0);
  const [sentTerm, setSentTerm] = useState<number>(0);
  const hostname = useContext(EnvContext);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);

  const getTermCode = (): number => {
    let termCode: number = 1000;
    const now = new Date();
    const currentYear = now.getFullYear() % 100;
    const currentMonth = now.getMonth() + 1; // Adding 1 because months are zero-indexed

    termCode += currentYear * 10;

    if (1 <= currentMonth && currentMonth < 5) {
      termCode += 1;
    } else if (5 <= currentMonth && currentMonth < 9) {
      termCode += 5;
    } else {
      termCode += 9;
    }

    return termCode;
  };

  const decodeTermCode = (termCode: number): string => {
    // Extracting year and season from the term code
    const year = Math.floor(termCode / 10);
    const seasonCode = termCode % 10;
    // Determining the season
    let season: string;
    switch (seasonCode) {
      case 1:
        season = "Winter";
        break;
      case 5:
        season = "Spring";
        break;
      case 9:
        season = "Fall";
        break;
      default:
        season = "Unknown";
    }
    // Combining year and season to form the description
    const description = `${season} 20${year - 100}`;
    return description;
  };

  useEffect(() => {
    const newTermCode = getTermCode();
    setTermCode(newTermCode);
    setSentTerm(newTermCode);

    return () => {
      setWesternCourseSections(null);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (clickedCourse) {
          setLoading(true);
          const response = await axios.post(
            `${hostname}/api/western/specific-course`,
            clickedCourse
          );
          setWesternCourseSections(response.data.data);
          setError(null); // Reset error if data fetched successfully
          console.log(response, "response");
          console.log(response.data);
          console.log(response.data.data);
          // Add a delay before hiding the loading spinner
          setTimeout(() => {
            setLoading(false);
          }, 500); // 0.5 seconds delay
        }
      } catch (error) {
        console.log(error);
        setError("Error fetching data");
        setWesternCourseSections(null); // Clear course data on error
        setLoading(false); // Ensure loading spinner is hidden on error
      }
    };

    fetchData();

    // Cleanup function to clear course data when unmounting or when changing courses
    return () => {
      setWesternCourseSections(null);
    };
  }, [clickedCourse, hostname]);

  const handleItemClick = (item: string) => {
    const originalName = decodeCourseName(item); // Decode the formatted name back to the original
    const split = originalName.split(" ");
    const name = split[0] + " " + split[1];
    setClickedCourse({ name: name });
    setCourseName(item); // Keep the formatted name as the display name
  };

  const formatCourseName = (course: string): string => {
    // Split the course string into two parts: the course code and the course title
    const matches = course.match(/^([A-Z]+\s\d+[A-Za-z]*)\s(.+)$/);

    if (!matches) return course; // Return the original if it doesn't match

    const courseCode = matches[1]; // The full course code including section identifier
    let title = matches[2].toLowerCase(); // The course title in lowercase

    // Capitalize the first letter of each word in the title
    title = title.replace(/\b\w/g, (char) => char.toUpperCase());

    // Ensure Roman numerals (I, II, III, IV, etc.) are in uppercase
    title = title.replace(
      /\b(i{1,3}|iv|v|vi{0,3}|ix|x{1,3}|xl|l{1,3})\b/gi,
      (roman) => roman.toUpperCase()
    );

    // Combine the course code and the formatted title
    return `${courseCode}: ${title}`;
  };

  const decodeCourseName = (formattedName: string): string => {
    // Split the formatted name by the colon to separate the code and the title
    const [courseCode, title] = formattedName.split(": ");
    if (!courseCode || !title) return formattedName;

    // Convert the title back to the original format (uppercase and spaces)
    let originalTitle = title.toUpperCase().replace(/\s+/g, " ");

    // Convert Roman numerals in the title to uppercase
    originalTitle = originalTitle.replace(
      /\b(i{1,3}|iv|v|vi{0,3}|ix|x{1,3}|xl|l{1,3})\b/gi,
      (roman) => roman.toUpperCase()
    );

    // Combine the course code with the original title
    return `${courseCode} ${originalTitle}`;
  };

  const formattedCourseList = westernAllCourseList.map(formatCourseName);
  return (
    <div>
      <div>
        <NavBar gradientColors={gradient} />
        <div className="wesTitleContainer">
          <div className="wesTitle">
            <div className="wesLogo">
              <img src={bothLogos} alt="Western University" />
            </div>
          </div>
          <div className="searchBarContainer">
            <SearchBar
              words={formattedCourseList}
              onItemClick={handleItemClick}
            />
          </div>
        </div>
        {loading && (
          <div className="loading-container">
            <l-ring
              size="40"
              stroke="4"
              bg-opacity="0"
              speed="2"
              color="white"
            ></l-ring>
          </div>
        )}
        {westernCourseSections && (
          <Sections
            sectionsList={westernCourseSections}
            courseName={courseName}
          />
        )}
        {error && (
          <div className="error-container">
            Error: {error ? error : "unable to find course info"}
          </div>
        )}
      </div>
      {!westernCourseSections && <div style={{ height: "383px" }}></div>}
      <Footer />
    </div>
  );
};

export default Western;
