import React, {
    useCallback,
    useState,
    useEffect,
    useRef,
    useContext,
} from 'react';
import Payment from '../../../../images/Logos/Payment.png';
import './SectionsStyle.css';
import Modal from '@mui/material/Modal';
import {
    IoCheckmarkCircleSharp,
    IoNotifications,
    IoClose,
    IoPerson,
    IoVideocam,
    IoLayers,
} from 'react-icons/io5';
import { MdQuestionMark } from 'react-icons/md';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { lineWobble, ring } from 'ldrs';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import EnvContext from '../../../ContextProviders/EnvContext';
import BlackLogo from '../../../../images/Logos/LogoBlack.png';
import { IoIosArrowDown } from 'react-icons/io';

ring.register();
lineWobble.register();

// change from _TEST to _LIVE on deploy

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE as string
);

interface CourseInfoAPIFormat {
    course_component: string;
    class_section: string;
    course_name: string;
    client_name: string;
    client_email: string;
    client_phone: string;
    subject_name: string;
}

interface SectionType {
    section: string;
    component: string;
    class_number: string;
    requisites: string;
    status: string;
    campus: string;
    delivery_type: string;
}

interface CourseSection {
    name: string;
    subject_name: string;
    code: string;
    description: string;
    antirequisites: string;
    extra_info: string;
    sections: SectionType[];
}

interface SectionsProps {
    sectionsList: CourseSection; // Define prop interface
    courseName: string;
}

const Sections: React.FC<SectionsProps> = ({ sectionsList, courseName }) => {
    const [clickedSections, setClickedSections] = useState<SectionType[]>([]);
    const [openCheckoutModal, setOpenCheckoutModal] = useState<boolean>(false);
    const [openConditionsModal, setOpenConditionsModal] =
        useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showCheckoutForm, setShowCheckoutForm] = useState<boolean>(false);
    const [isStripeOpen, setIsStripeOpen] = useState(false);
    const [sessionId, setSessionId] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
    const [showInstructions, setShowInstructions] = useState(false);

    const checkoutRef = useRef<HTMLDivElement>(null); // Reference to the checkout element
    const hostname = useContext(EnvContext);

    useEffect(() => {
        setClickedSections([]);
    }, [sectionsList]);

    const toggleSectionColor = (section: SectionType) => {
        if (
            clickedSections.some((clickedSection) => clickedSection === section)
        ) {
            setClickedSections(
                clickedSections.filter(
                    (clickedSection) => clickedSection !== section
                )
            );
        } else {
            setClickedSections([...clickedSections, section]);
        }
    };

    const toggleInstructions = () => {
        setShowInstructions(!showInstructions);
    };

    const proceedToCheckout = async () => {
        if (clickedSections.length > 0) {
            setErrorMessage('');
            setOpenCheckoutModal(true);
        } else {
            setErrorMessage('Please select at least one section.');
        }
    };

    const sendSelectedCourses = useCallback(
        async (courseName: string) => {
            const formattedSections: CourseInfoAPIFormat[] =
                clickedSections.map((section) => ({
                    course_component: section.component,
                    class_section: section.section,
                    course_name: courseName,
                    client_name: name ? name : '__delete__',
                    client_email: email,
                    client_phone: phoneNumber ? phoneNumber : '0',
                    subject_name: sectionsList.subject_name,
                }));
            try {
                const response = await axios.post(
                    `${hostname}/api/western/create-db-entry`,
                    formattedSections
                );
                if (response.status >= 200 && response.status < 300) {
                    console.log(response);
                    setIsPaymentLoading(false);
                    window.location.href = '/success';
                } else {
                    console.log(
                        'The request was not successful. Status code:',
                        response.status
                    );
                }
            } catch (error) {
                console.error('Error saving your data', error);
            }
        },
        [clickedSections, name, email, phoneNumber, hostname]
    );

    useEffect(() => {
        let isMounted = true;

        const pollSessionStatus = async () => {
            try {
                const response = await axios.post(
                    `${hostname}/api/western/session-status`,
                    { sessionId: sessionId }
                );
                const session = response.data;
                if (session.status === 'complete') {
                    console.log('Session is complete!');
                    sendSelectedCourses(courseName);
                    if (isMounted) {
                        setOpenCheckoutModal(false);
                    }
                } else {
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                    if (isMounted && openCheckoutModal) {
                        pollSessionStatus();
                    }
                }
            } catch (error) {
                console.error('Error checking session status:', error);
            }
        };

        if (sessionId && openCheckoutModal) {
            pollSessionStatus();
        }

        return () => {
            isMounted = false;
        };
    }, [
        sessionId,
        openCheckoutModal,
        sendSelectedCourses,
        courseName,
        hostname,
    ]);

    const fetchClientSecret = useCallback(() => {
        setIsLoading(true); // Start loading when the request is made
        return axios
            .post(`${hostname}/api/western/create-checkout-session`, {
                email: email,
            })
            .then((res) => {
                const { clientSecret, sessionId } = res.data;
                setSessionId(sessionId);
                setIsLoading(false); // Stop loading once the clientSecret is retrieved
                return clientSecret;
            })
            .catch((error) => {
                console.error('Error fetching client secret:', error);
                setIsLoading(false); // Stop loading in case of error
                throw new Error('Failed to fetch client secret');
            });
    }, [email, hostname]);

    const options = { fetchClientSecret };

    const handleCheckoutClick = () => {
        if (validateEmail(email)) {
            setEmailError('');
            setIsStripeOpen(true);
            setShowCheckoutForm(true);
        } else {
            setEmailError('Please enter a valid email.');
        }
    };

    const scrollToStripe = () => {
        if (checkoutRef.current) {
            checkoutRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };
    const handlePhoneNumberChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPhoneNumber(event.target.value);
    };

    const handleCloseModal = () => {
        setIsStripeOpen(false);
        setIsLoading(true);
        setOpenCheckoutModal(false);
        setEmailError('');
        setShowCheckoutForm(false); // Reset checkout form visibility
    };

    const validateEmail = (email: string) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const formattedSections: CourseInfoAPIFormat[] = clickedSections.map(
        (section) => ({
            course_component: section.component,
            class_section: section.section,
            course_name: courseName,
            client_name: name ? name : '__delete__',
            client_email: email,
            client_phone: phoneNumber ? phoneNumber : '0',
            subject_name: sectionsList.subject_name,
        })
    );

    return (
        <>
            {openCheckoutModal && (
                <Modal open={openCheckoutModal} onClose={handleCloseModal}>
                    <div className="modal-background">
                        <div
                            className="close-button"
                            onClick={handleCloseModal}
                        >
                            <IoClose />
                        </div>
                        <div className="modal-content">
                            <div className="modal-title">
                                Selected Section(s) for
                                <p className="modal-course">{courseName}</p>
                            </div>
                            <div className="sections-modal-container">
                                {clickedSections.map((section, index) => (
                                    <div className="sections-modal" key={index}>
                                        {section.component}{' '}
                                        {section.section
                                            .toString()
                                            .padStart(3, '0')}
                                    </div>
                                ))}
                            </div>
                            <input
                                className="input-field"
                                type="text"
                                placeholder="Name (optional)"
                                value={name}
                                onChange={handleNameChange}
                            />
                            <input
                                className={`input-field ${emailError ? 'input-error' : ''}`}
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={handleEmailChange}
                                required
                            />
                            <input
                                className="input-field"
                                type="phone"
                                placeholder="Phone Number (optional)"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                            />
                            {isStripeOpen ? (
                                <div
                                    className="checkout"
                                    onClick={scrollToStripe}
                                >
                                    {isLoading ? (
                                        <l-line-wobble
                                            size="140"
                                            stroke="5"
                                            bg-opacity="0.1"
                                            speed="1.75"
                                            color="white"
                                        ></l-line-wobble>
                                    ) : (
                                        <div className="modal-arrow">
                                            <IoIosArrowDown />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="checkout-align">
                                    <div
                                        className={`checkout ${
                                            validateEmail(email)
                                                ? ''
                                                : 'disabled'
                                        }`}
                                        onClick={handleCheckoutClick}
                                    >
                                        Confirm Order
                                    </div>
                                    <img
                                        className="payment-images"
                                        src={Payment}
                                        alt="Payment Methods"
                                    />
                                </div>
                            )}
                            {showCheckoutForm && (
                                <div>
                                    <div
                                        className="stripe-checkout"
                                        ref={checkoutRef}
                                    >
                                        <EmbeddedCheckoutProvider
                                            stripe={stripePromise}
                                            options={options}
                                        >
                                            <EmbeddedCheckout />
                                        </EmbeddedCheckoutProvider>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
            )}
            <div className="wes-help-button-container">
                {showInstructions ? (
                    <div
                        className="wes-help-button"
                        onClick={toggleInstructions}
                    >
                        <IoClose style={{ fontSize: '20px' }} />
                    </div>
                ) : (
                    <div
                        className="wes-help-button"
                        onClick={toggleInstructions}
                    >
                        <MdQuestionMark />
                    </div>
                )}
            </div>

            <div className="wes-section-instructions-parent">
                <div
                    className={`wes-section-instructions ${showInstructions ? 'open' : 'closed'}`}
                >
                    <div className="wes-instruction-step">
                        <div className="wes-number-circle icon">1</div>
                        <div className="wes-sections-description">
                            To get notifications for full sections, click the '
                            <IoNotifications
                                style={{
                                    verticalAlign: 'middle',
                                    position: 'relative',
                                    top: '-3px',
                                }}
                            />{' '}
                            Full: Get Alerts' button.
                        </div>
                    </div>
                    <div className="wes-instruction-step">
                        <div className="wes-number-circle icon">2</div>
                        <div className="wes-sections-description">
                            Once you've chosen all your sections, scroll down
                            and click 'Continue'.
                        </div>
                    </div>
                </div>
            </div>
            <div className="sections-container">
                {sectionsList.sections.map(
                    (section: SectionType, index: number) => (
                        <div
                            key={index}
                            className={`wes-section-box ${
                                clickedSections.includes(section)
                                    ? 'clicked'
                                    : ''
                            }`}
                        >
                            <div className="section-header">
                                <div className="section-info">
                                    <h3 className="section-number">
                                        {section.component} {section.section}
                                    </h3>
                                    {clickedSections.includes(section) && (
                                        <IoCheckmarkCircleSharp className="checkmark-icon" />
                                    )}
                                </div>
                                {section.status === 'Full' ? (
                                    <div
                                        className="wes-button"
                                        onClick={() =>
                                            toggleSectionColor(section)
                                        }
                                    >
                                        <IoNotifications />
                                        <p>Full: Get Alerts</p>
                                    </div>
                                ) : (
                                    <p>Status: {section.status}</p>
                                )}
                            </div>
                            <div className="details">
                                <div className="class-number">
                                    Class Number: {section.class_number}
                                </div>{' '}
                                <p>
                                    {section.delivery_type === 'In Person' ? (
                                        <>
                                            <IoPerson /> In Person
                                        </>
                                    ) : section.delivery_type ===
                                      'Distance Studies/Online' ? (
                                        <>
                                            <IoVideocam /> Online
                                        </>
                                    ) : section.delivery_type === 'Blended' ? (
                                        <>
                                            <IoLayers /> Blended
                                        </>
                                    ) : (
                                        section.delivery_type
                                    )}
                                </p>
                            </div>
                        </div>
                    )
                )}
            </div>
            {sectionsList.sections.length > 0 && (
                <div className="proceed-button-div">
                    <div
                        className="checkout"
                        style={{ width: '150px' }}
                        onClick={proceedToCheckout}
                    >
                        Continue
                    </div>
                    <p className="terms-of-service">
                        By clicking Continue, you agree to our{' '}
                        <span
                            className="terms-link"
                            onClick={() => setOpenConditionsModal(true)}
                        >
                            Terms of Service
                        </span>
                        .
                    </p>
                </div>
            )}
            {openConditionsModal && (
                <Modal
                    open={openConditionsModal}
                    onClose={() => setOpenConditionsModal(false)}
                >
                    <div className="modal-background">
                        <div
                            className="close-button"
                            onClick={() => setOpenConditionsModal(false)}
                        >
                            <IoClose />
                        </div>
                        <div className="modal-content">
                            <img
                                className="conditions-logo"
                                src={BlackLogo}
                                alt="Course Clutch"
                            />
                            <h2>Terms of Service</h2>
                            <div className="service-info">
                                <p>
                                    This service operates independently and is
                                    not officially affiliated with any
                                    educational institution.
                                </p>
                                <p>
                                    While it cannot guarantee admission to
                                    courses, it diligently monitors for
                                    available spots or openings on the waitlist
                                    and promptly notifies users via email.
                                </p>
                                <p>
                                    Although it relies on university-provided
                                    data, accuracy may vary if the data is
                                    outdated or improperly updated. While it
                                    attempts to interpret data accurately,
                                    university course restrictions may not be
                                    accounted for.
                                </p>
                                <p>
                                    Please note that the service reserves the
                                    right to discontinue operations at any time,
                                    and ongoing transactions are non-refundable.
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <div>
                {errorMessage && (
                    <div className="error-sections">{errorMessage}</div>
                )}
            </div>
        </>
    );
};

export default Sections;
