import React, {
    useCallback,
    useState,
    useEffect,
    useRef,
    useContext,
} from 'react';
import Payment from '../../../../images/Logos/Payment.png';
import './SectionsStyle.css';
import Modal from '@mui/material/Modal';
import {
    IoCheckmarkCircleSharp,
    IoNotifications,
    IoClose,
} from 'react-icons/io5';
import { IoIosArrowDown } from 'react-icons/io';
import { MdQuestionMark } from 'react-icons/md';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { lineWobble, ring } from 'ldrs';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import EnvContext from '../../../ContextProviders/EnvContext';
import BlackLogo from '../../../../images/Logos/LogoBlack.png';

ring.register();
lineWobble.register();

// change from _TEST to _LIVE on deploy

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE as string
);

interface ScheduleData {
    scheduleStartDate: string;
    scheduleEndDate: string;
    classMeetingStartTime: string;
    classMeetingEndTime: string;
    classMeetingWeekPatternCode: string;
}

interface CourseInfoAPIFormat {
    class_number: number;
    term_code: string;
    course_id: string;
    course_component: string;
    class_section: number;
    course_name: string;
    client_name: string;
    client_email: string;
    client_phone: string;
}

interface CourseSection {
    classNumber: number;
    termCode: string;
    courseId: string;
    courseComponent: string;
    classSection: number;
    courseName: string;
    name: string;
    email: string;
    phone: string;
    maxEnrollmentCapacity: number;
    enrolledStudents: number;
    scheduleData: ScheduleData[];
}

interface SectionsProps {
    sectionsList: CourseSection[];
    courseName: string;
}

const Sections: React.FC<SectionsProps> = ({ sectionsList, courseName }) => {
    const [clickedSections, setClickedSections] = useState<CourseSection[]>([]);
    const [openCheckoutModal, setOpenCheckoutModal] = useState<boolean>(false);
    const [openConditionsModal, setOpenConditionsModal] =
        useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showCheckoutForm, setShowCheckoutForm] = useState<boolean>(false);
    const [isStripeOpen, setIsStripeOpen] = useState(false);
    const [sessionId, setSessionId] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
    const [showInstructions, setShowInstructions] = useState(false);

    const checkoutRef = useRef<HTMLDivElement>(null);
    const hostname = useContext(EnvContext);

    useEffect(() => {
        setClickedSections([]);
    }, [sectionsList]);

    const sortedSections = sectionsList.slice().sort((a, b) => {
        const courseComponentComparison = a.courseComponent.localeCompare(
            b.courseComponent
        );
        if (courseComponentComparison !== 0) {
            return courseComponentComparison;
        }
        return a.classSection - b.classSection;
    });

    const toggleSectionColor = (section: CourseSection) => {
        if (
            clickedSections.some((clickedSection) => clickedSection === section)
        ) {
            setClickedSections(
                clickedSections.filter(
                    (clickedSection) => clickedSection !== section
                )
            );
        } else {
            setClickedSections([...clickedSections, section]);
        }
    };

    const toggleInstructions = () => {
        setShowInstructions(!showInstructions);
    };

    const fetchClientSecret = useCallback(() => {
        setIsLoading(true);
        return axios
            .post(`${hostname}/api/waterloo/create-checkout-session`, {
                email: email,
            })
            .then((res) => {
                const { clientSecret, sessionId } = res.data;
                setSessionId(sessionId);
                setIsLoading(false);
                return clientSecret;
            })
            .catch((error) => {
                console.error('Error fetching client secret:', error);
                setIsLoading(false);
                throw new Error('Failed to fetch client secret');
            });
    }, [email, hostname]);

    const options = { fetchClientSecret };

    const proceedToCheckout = async () => {
        if (clickedSections.length > 0) {
            setErrorMessage('');
            setOpenCheckoutModal(true);
        } else {
            setErrorMessage('Please select at least one section.');
        }
    };

    const sendSelectedCourses = useCallback(
        async (courseName: string) => {
            const formattedSections: CourseInfoAPIFormat[] =
                clickedSections.map((section) => ({
                    class_number: section.classNumber,
                    term_code: section.termCode,
                    course_id: section.courseId,
                    course_component: section.courseComponent,
                    class_section: section.classSection,
                    course_name: courseName,
                    client_name: name ? name : '__delete__',
                    client_email: email,
                    client_phone: phoneNumber ? phoneNumber : '0',
                }));
            console.log(formattedSections);
            try {
                const response = await axios.post(
                    `${hostname}/api/waterloo/create-db-entry`,
                    formattedSections
                );
                if (response.status >= 200 && response.status < 300) {
                    console.log(response);
                    setIsPaymentLoading(false);
                    window.location.href = '/success';
                } else {
                    console.log(
                        'The request was not successful. Status code:',
                        response.status
                    );
                }
            } catch (error) {
                console.error('Error saving your data', error);
            }
        },
        [clickedSections, name, email, phoneNumber, hostname]
    );

    useEffect(() => {
        let isMounted = true;

        const pollSessionStatus = async () => {
            try {
                const response = await axios.post(
                    `${hostname}/api/waterloo/session-status`,
                    { sessionId: sessionId }
                );
                const session = response.data;
                if (session.status === 'complete') {
                    console.log('Session is complete!');
                    sendSelectedCourses(courseName);
                    if (isMounted) {
                        setOpenCheckoutModal(false);
                    }
                } else {
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                    if (isMounted && openCheckoutModal) {
                        pollSessionStatus();
                    }
                }
            } catch (error) {
                console.error('Error checking session status:', error);
            }
        };

        if (sessionId && openCheckoutModal) {
            pollSessionStatus();
        }

        return () => {
            isMounted = false;
        };
    }, [
        sessionId,
        openCheckoutModal,
        sendSelectedCourses,
        courseName,
        hostname,
    ]);

    const handleCheckoutClick = () => {
        if (validateEmail(email)) {
            setEmailError('');
            setIsStripeOpen(true);
            setShowCheckoutForm(true);
        } else {
            setEmailError('Please enter a valid email.');
        }
    };

    const scrollToStripe = () => {
        if (checkoutRef.current) {
            checkoutRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };
    const handlePhoneNumberChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPhoneNumber(event.target.value);
    };

    const handleCloseModal = () => {
        setIsStripeOpen(false);
        setIsLoading(true);
        setOpenCheckoutModal(false);
        setEmailError('');
        setShowCheckoutForm(false);
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const formatTime = (time: string) => {
        return time
            ? new Date(time).toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
              })
            : '-';
    };

    const formatDayPattern = (weekPatternCode: string) => {
        const days = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su'];
        return days.map((day, index) =>
            weekPatternCode.charAt(index) === 'Y' ? (
                <strong key={index} className="selected">
                    {day}
                </strong>
            ) : (
                <strong key={index}>{day}</strong>
            )
        );
    };

    const formatDate = (startDate: string, endDate: string) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const options: Intl.DateTimeFormatOptions = {
            month: 'short',
            day: 'numeric',
        };
        if (start.toDateString() === end.toDateString()) {
            return new Date(startDate).toLocaleDateString('en-US', options);
        } else {
            return ''; // Return an empty string if it's a range
        }
    };

    return (
        <>
            {isPaymentLoading && (
                <div className="stripe-loading-container">
                    <l-ring
                        size="40"
                        stroke="4"
                        bg-opacity="0"
                        speed="2"
                        color="white"
                    ></l-ring>
                </div>
            )}

            <div>
                {openCheckoutModal && (
                    <Modal open={openCheckoutModal} onClose={handleCloseModal}>
                        <div className="modal-background">
                            <div
                                className="close-button"
                                onClick={handleCloseModal}
                            >
                                <IoClose />
                            </div>
                            <div className="modal-content">
                                <div className="modal-title">
                                    Selected Section(s) for
                                    <p className="modal-course">{courseName}</p>
                                </div>
                                <div className="sections-modal-container">
                                    {clickedSections.map((section, index) => (
                                        <div
                                            className="sections-modal"
                                            key={index}
                                        >
                                            {section.courseComponent}{' '}
                                            {section.classSection
                                                .toString()
                                                .padStart(3, '0')}
                                        </div>
                                    ))}
                                </div>
                                <input
                                    className="input-field"
                                    type="text"
                                    placeholder="Name (optional)"
                                    value={name}
                                    onChange={handleNameChange}
                                />
                                <input
                                    className={`input-field ${emailError ? 'input-error' : ''}`}
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                                <input
                                    className="input-field"
                                    type="phone"
                                    placeholder="Phone Number (optional)"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                />
                                {isStripeOpen ? (
                                    <div
                                        className="checkout"
                                        onClick={scrollToStripe}
                                    >
                                        {isLoading ? (
                                            <l-line-wobble
                                                size="140"
                                                stroke="5"
                                                bg-opacity="0.1"
                                                speed="1.75"
                                                color="white"
                                            ></l-line-wobble>
                                        ) : (
                                            <div className="modal-arrow">
                                                <IoIosArrowDown />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="checkout-align">
                                        <div
                                            className={`checkout ${
                                                validateEmail(email)
                                                    ? ''
                                                    : 'disabled'
                                            }`}
                                            onClick={handleCheckoutClick}
                                        >
                                            Confirm Order
                                        </div>
                                        <img
                                            className="payment-images"
                                            src={Payment}
                                            alt="Payment Methods"
                                        />
                                    </div>
                                )}
                                {showCheckoutForm && (
                                    <div>
                                        <div
                                            className="stripe-checkout"
                                            ref={checkoutRef}
                                        >
                                            <EmbeddedCheckoutProvider
                                                stripe={stripePromise}
                                                options={options}
                                            >
                                                <EmbeddedCheckout />
                                            </EmbeddedCheckoutProvider>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Modal>
                )}
                {sectionsList.length > 0 && (
                    <>
                        <div className="wat-help-button-container">
                            {showInstructions ? (
                                <div
                                    className="wat-help-button"
                                    onClick={toggleInstructions}
                                >
                                    <IoClose style={{ fontSize: '20px' }} />
                                </div>
                            ) : (
                                <div
                                    className="wat-help-button"
                                    onClick={toggleInstructions}
                                >
                                    <MdQuestionMark />
                                </div>
                            )}
                        </div>

                        <div className="wat-section-instructions-parent">
                            <div
                                className={`wat-section-instructions ${showInstructions ? 'open' : 'closed'}`}
                            >
                                <div className="wat-instruction-step">
                                    <div className="wat-number-circle icon">
                                        1
                                    </div>
                                    <div className="wat-sections-description">
                                        To get notifications for full sections,
                                        click the '
                                        <IoNotifications
                                            style={{
                                                verticalAlign: 'middle',
                                                position: 'relative',
                                                top: '-3px',
                                            }}
                                        />{' '}
                                        Full: Get Alerts' button.
                                    </div>
                                </div>
                                <div className="wat-instruction-step">
                                    <div className="wat-number-circle icon">
                                        2
                                    </div>
                                    <div className="wat-sections-description">
                                        Once you've chosen all your sections,
                                        scroll down and click 'Continue'.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="wat-sections-container">
                    {sortedSections.map(
                        (section: CourseSection, index: number) => (
                            <div
                                key={index}
                                className={`wat-section-box ${
                                    clickedSections.includes(section)
                                        ? 'clicked'
                                        : ''
                                }`}
                            >
                                <div className="wat-section-header">
                                    <div className="section-info">
                                        <h3>
                                            {section.courseComponent}{' '}
                                            {section.classSection
                                                .toString()
                                                .padStart(3, '0')}
                                        </h3>

                                        {clickedSections.includes(section) && (
                                            <IoCheckmarkCircleSharp className="checkmark-icon" />
                                        )}
                                    </div>
                                    {section.enrolledStudents >=
                                    section.maxEnrollmentCapacity ? (
                                        <div
                                            className="wat-button"
                                            onClick={() =>
                                                toggleSectionColor(section)
                                            }
                                        >
                                            <IoNotifications />
                                            <p>Full: Get Alerts</p>
                                        </div>
                                    ) : (
                                        <p>
                                            Enrolled: {section.enrolledStudents}
                                            /{section.maxEnrollmentCapacity}
                                        </p>
                                    )}
                                </div>
                                <div className="wat-details">
                                    <p>Class Times:</p>
                                    <p>
                                        {section.scheduleData.map(
                                            (
                                                schedule: ScheduleData,
                                                index: number
                                            ) => (
                                                <span key={index}>
                                                    {formatDayPattern(
                                                        schedule.classMeetingWeekPatternCode
                                                    ).map((day, index) => (
                                                        <strong
                                                            key={index}
                                                            className={
                                                                schedule.classMeetingWeekPatternCode.charAt(
                                                                    index
                                                                ) === 'Y'
                                                                    ? 'selected'
                                                                    : ''
                                                            }
                                                        >
                                                            {day}
                                                        </strong>
                                                    ))}{' '}
                                                    {formatTime(
                                                        schedule.classMeetingStartTime
                                                    )}{' '}
                                                    -{' '}
                                                    {formatTime(
                                                        schedule.classMeetingEndTime
                                                    )}{' '}
                                                    {formatDate(
                                                        schedule.scheduleStartDate,
                                                        schedule.scheduleEndDate
                                                    ) && (
                                                        <>
                                                            (
                                                            {formatDate(
                                                                schedule.scheduleStartDate,
                                                                schedule.scheduleEndDate
                                                            )}
                                                            )
                                                        </>
                                                    )}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </p>
                                </div>
                            </div>
                        )
                    )}
                </div>
                {sectionsList.length > 0 && (
                    <div className="proceed-button-div">
                        <div
                            className="checkout"
                            style={{ width: '150px' }}
                            onClick={proceedToCheckout}
                        >
                            Continue
                        </div>
                        <p className="terms-of-service">
                            By clicking Continue, you agree to our{' '}
                            <span
                                className="terms-link"
                                onClick={() => setOpenConditionsModal(true)}
                            >
                                Terms of Service
                            </span>
                            .
                        </p>
                    </div>
                )}
                {openConditionsModal && (
                    <Modal
                        open={openConditionsModal}
                        onClose={() => setOpenConditionsModal(false)}
                    >
                        <div className="modal-background">
                            <div
                                className="close-button"
                                onClick={() => setOpenConditionsModal(false)}
                            >
                                <IoClose />
                            </div>
                            <div className="modal-content">
                                <img
                                    className="conditions-logo"
                                    src={BlackLogo}
                                    alt="Course Clutch"
                                />
                                <h2>Terms of Service</h2>
                                <div className="service-info">
                                    <p>
                                        This service operates independently and
                                        is not officially affiliated with any
                                        educational institution.
                                    </p>
                                    <p>
                                        While it cannot guarantee admission to
                                        courses, it diligently monitors for
                                        available spots or openings on the
                                        waitlist and promptly notifies users via
                                        email.
                                    </p>
                                    <p>
                                        Although it relies on
                                        university-provided data, accuracy may
                                        vary if the data is outdated or
                                        improperly updated. While it attempts to
                                        interpret data accurately, university
                                        course restrictions may not be accounted
                                        for.
                                    </p>
                                    <p>
                                        Please note that the service reserves
                                        the right to discontinue operations at
                                        any time, and ongoing transactions are
                                        non-refundable.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
                <div>
                    {errorMessage && (
                        <div className="error-sections">{errorMessage}</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Sections;
