import React, { useState, useEffect, useRef } from 'react';
import Fuse from 'fuse.js'; // Import Fuse from 'fuse.js'
import './SearchBarStyle.css';
import { IoMdSearch } from 'react-icons/io';

interface Props {
    words: string[];
    onItemClick: (item: string) => void;
}

const SearchBar: React.FC<Props> = ({ words, onItemClick }) => {
    const [filteredWords, setFilteredWords] = useState<string[]>([]);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [inputWidth, setInputWidth] = useState<number>(0); // State for input width
    const [isDropdownExpanded, setIsDropdownExpanded] =
        useState<boolean>(false);
    const searchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const updateDropdownWidth = () => {
            if (searchRef.current) {
                const newInputWidth = searchRef.current.offsetWidth;
                setInputWidth(newInputWidth); // Update input width state
            }
        };
        window.addEventListener('resize', updateDropdownWidth);
        updateDropdownWidth(); // Call the function initially
        return () => {
            window.removeEventListener('resize', updateDropdownWidth);
        };
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const userInput = e.target.value.toLowerCase();
        if (userInput.length === 0) {
            setFilteredWords([]);
            setIsDropdownExpanded(false); // Close the dropdown when input is cleared
            return;
        }

        const fuse = new Fuse(words);
        const result = fuse.search(userInput);
        const matchedWords = result.slice(0, 6).map((item: any) => item.item);

        setFilteredWords(matchedWords);
        setIsDropdownExpanded(matchedWords.length > 0); // Show dropdown if there are matches
    };

    const handleItemClick = (item: string) => {
        const items = document.querySelectorAll('ul li');
        items.forEach((item) => item.classList.remove('selected'));
        const clickedItem = document.querySelector(
            `ul li:nth-of-type(${filteredWords.indexOf(item) + 1})`
        );
        clickedItem?.classList.add('selected');
        onItemClick(item);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && filteredWords.length > 0) {
            const items = document.querySelectorAll('ul li');
            items.forEach((item) => item.classList.remove('selected'));
            const clickedItem = document.querySelector(
                `ul li:nth-of-type(${filteredWords.indexOf(filteredWords[0]) + 1})`
            );
            clickedItem?.classList.add('selected');
            onItemClick(filteredWords[0]);
        }
    };

    return (
        <div
            className={`container ${isFocused ? 'focused' : ''} ${isDropdownExpanded ? 'dropdown-open' : ''}`}
        >
            <div className="search-icon">
                <IoMdSearch />
            </div>
            <input
                id="search"
                type="text"
                placeholder="Search Courses"
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                ref={searchRef} // Attach a ref to the input
            />
            <ul
                className={`drop ${filteredWords.length > 0 ? 'expanded' : ''}`}
                style={{ maxWidth: inputWidth - 2 }} // Set the maxWidth inline style
            >
                {filteredWords.map((item, index) => (
                    <li
                        key={index}
                        className={
                            item.toLowerCase() ===
                            filteredWords[0]?.toLowerCase()
                                ? 'match'
                                : ''
                        }
                        onClick={() => handleItemClick(item)}
                    >
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SearchBar;
