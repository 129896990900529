import { useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../Assets/NavBar/NavBar';
import Footer from '../Assets/Footer/Footer';
import './HomeStyling.css';
import { FaUsersSlash } from 'react-icons/fa';
import { BsFillLightningFill } from 'react-icons/bs';
import { FaCalendarWeek } from 'react-icons/fa6';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'; // Import the icons
import { IoClose } from 'react-icons/io5';
import Modal from '@mui/material/Modal';
import BlackLogo from '../../images/Logos/LogoBlack.png';
import { IoIosRocket } from 'react-icons/io';
import { useTypewriter, Cursor } from 'react-simple-typewriter';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import WaterlooShield from '../../images/UniversityLogos/WaterlooShield.png';
import WesternShield from '../../images/UniversityLogos/WesternShield.png';
import homePagePng from '../../images/Homepage/home.png';
import searchPng from '../../images/Homepage/search.png';
import modalPng from '../../images/Homepage/modal.png';
import courseSelectPng from '../../images/Homepage/select-course.png';
import WaterlooHorizontal from '../../images/UniversityLogos/WaterlooHorizontal.png';
import WesternHorizontal from '../../images/UniversityLogos/WesternHorizontal.png';

const randomWords = [
    'Exciting.',
    'Simple.',
    'Easy.',
    'Fast.',
    'Reliable.',
    'Seamless.',
    'Effortless.',
    'Painless.',
    'Efficient.',
    'Smooth.',
];

const sortedWords = randomWords.sort(() => Math.random() - 0.5); // Randomize the order of words once

const Home = () => {
    const [text] = useTypewriter({
        words: sortedWords,
        loop: false,
        typeSpeed: 200,
        deleteSpeed: 100,
        delaySpeed: 5000,
    });
    const [openGetStartedModal, setOpenGetStartedModal] =
        useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);

    const handleCloseModal = () => {
        setOpenGetStartedModal(false);
    };

    const openImageModal = (image: string) => {
        setSelectedImage(image);
        setIsImageModalOpen(true);
    };

    const closeImageModal = () => {
        setIsImageModalOpen(false);
        setSelectedImage(null);
    };

    return (
        <div className="homepage-container">
            {openGetStartedModal && (
                <Modal open={openGetStartedModal} onClose={handleCloseModal}>
                    <div className="homemodal-background">
                        <div
                            className="close-button"
                            onClick={handleCloseModal}
                        >
                            <IoClose />
                        </div>
                        <div className="homemodal-content">
                            <img
                                className="conditions-logo"
                                src={BlackLogo}
                                alt="Course Clutch"
                            />
                            <h2>Get Started!</h2>
                            <div className="choose-uni-modal">
                                <div className="modal-number-circle icon">
                                    1
                                </div>{' '}
                                <div className="homemodal-description">
                                    Choose Your University
                                </div>
                            </div>
                            <div className="homemodal-options">
                                <Link
                                    to="/waterloo"
                                    className="uni-box"
                                    style={{
                                        width: '100%',
                                        height: '120px',
                                    }}
                                >
                                    <img
                                        src={WaterlooHorizontal}
                                        alt="University of Waterloo"
                                        className="homemodal-logo"
                                        style={{ marginBottom: '-10px' }}
                                    />
                                </Link>
                                <Link
                                    to="/western"
                                    className="uni-box"
                                    style={{
                                        width: '100%',
                                        height: '120px',
                                    }}
                                >
                                    <img
                                        src={WesternHorizontal}
                                        alt="Western University"
                                        className="homemodal-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <div className="big-container">
                <NavBar gradientColors={['#662d8c', '#ed1e79']} />
                <div className="home-container">
                    <div className="background-dots"></div>
                    <div className="home-content">
                        <div
                            className="title-container"
                            style={{
                                position: 'relative',
                                display: 'inline-block',
                            }}
                        >
                            <h1 className="home-title">
                                Course Enrollment <br />
                                Made{' '}
                                <span className="dynamic-text">{text}</span>
                                <span>
                                    <Cursor
                                        cursorBlinking
                                        cursorColor="darkgray"
                                        cursorStyle={'|'}
                                    />{' '}
                                </span>
                            </h1>
                        </div>
                        <div className="under-title">
                            <p className="home-description">
                                Course Clutch simplifies university course
                                selection. Gain access to real-time updates and
                                secure your ideal schedule affordably.
                            </p>
                            <div
                                className="get-started-button"
                                onClick={() => setOpenGetStartedModal(true)}
                            >
                                <IoIosRocket
                                    style={{
                                        color: '#fff',
                                        paddingRight: '15px',
                                    }}
                                />
                                Get Started!
                            </div>
                            <div>
                                <h2 className="our-unis">
                                    Supported Universities
                                </h2>
                                <div className="universities">
                                    <Swiper
                                        modules={[Navigation, Autoplay]}
                                        spaceBetween={30}
                                        slidesPerView={1}
                                        navigation={{
                                            nextEl: '.swiper-button-next-custom', // Custom next button
                                            prevEl: '.swiper-button-prev-custom', // Custom prev button
                                        }}
                                        pagination={{ clickable: true }}
                                        loop={true}
                                        autoplay={{
                                            delay: 5000,
                                            disableOnInteraction: false,
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className="swiper">
                                                <Link to="/waterloo">
                                                    <div className="uni-box">
                                                        <img
                                                            className="waterloo-shield"
                                                            src={WaterlooShield}
                                                            alt="University of Waterloo"
                                                        />
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="swiper">
                                                <Link to="/western">
                                                    <div className="uni-box">
                                                        <img
                                                            className="western-shield"
                                                            src={WesternShield}
                                                            alt="Western University"
                                                        />
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    <div className="swiper-button-prev-custom">
                                        <IoIosArrowBack size={30} />
                                    </div>
                                    <div className="swiper-button-next-custom">
                                        <IoIosArrowForward size={30} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Added infogrid and how-to-use sections */}
                    <div className="infogrid-container">
                        <div className="infogrid">
                            <div className="infogrid-content">
                                <div className="infogrid-icons">
                                    <FaUsersSlash />
                                </div>
                                <strong>No Login Required</strong>
                                <p>
                                    We will never require your university
                                    credentials.
                                </p>
                            </div>
                        </div>
                        <div className="infogrid">
                            <div className="infogrid-content">
                                <div
                                    className="infogrid-icons"
                                    style={{ color: '#EAAB00' }}
                                >
                                    <BsFillLightningFill />
                                </div>
                                <strong>Lightning Fast Updates</strong>
                                <p>
                                    We check every 10 minutes for course
                                    openings and notify you immediately.
                                </p>
                            </div>
                        </div>
                        <div className="infogrid2">
                            <div className="infogrid-content">
                                <div className="infogrid-icons">
                                    <FaCalendarWeek />
                                </div>
                                <strong>14 Day Monitoring Period</strong>
                                <p>
                                    We will track section availability for 2
                                    weeks or until a spot becomes available.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="how-to-use">
                        <h1
                            style={{
                                paddingTop: '20px',
                                marginBottom: '-20px',
                            }}
                        >
                            Step-By-Step
                        </h1>
                        <div className="timeline-container">
                            <div className="wrapper">
                                <div className="center-line">
                                    <a className="scroll-icon">
                                        <i className="fas fa-caret-up"></i>
                                    </a>
                                </div>
                                <div className="row row-1">
                                    <section>
                                        <i
                                            style={{
                                                boxShadow:
                                                    '0 2px 8px rgba(0, 0, 0, 0.4)',
                                            }}
                                            className="icon fas fa-home"
                                        >
                                            1
                                        </i>
                                        <div className="details">
                                            <span className="title">
                                                Select Your School
                                            </span>
                                        </div>
                                        <p>
                                            Click on the box containing your
                                            university.
                                        </p>
                                        <div className="infogrid-2">
                                            <img
                                                className="how-to-png"
                                                src={homePagePng}
                                                alt="home-png"
                                                onClick={() =>
                                                    openImageModal(homePagePng)
                                                }
                                            />
                                        </div>
                                    </section>
                                </div>
                                <div className="row row-2">
                                    <section>
                                        <i
                                            style={{
                                                boxShadow:
                                                    '0 2px 8px rgba(0, 0, 0, 0.4)',
                                            }}
                                            className="icon fas fa-map-marker-alt"
                                        >
                                            2
                                        </i>
                                        <div className="details">
                                            <span className="title">
                                                Search For A Course
                                            </span>
                                        </div>
                                        <p>
                                            Search by course name or code and
                                            select a course.
                                        </p>
                                        <div className="infogrid-2">
                                            <img
                                                className="how-to-png"
                                                src={searchPng}
                                                alt="home-png"
                                                onClick={() =>
                                                    openImageModal(searchPng)
                                                }
                                            />
                                        </div>
                                    </section>
                                </div>
                                <div className="row row-1">
                                    <section>
                                        <i
                                            style={{
                                                marginRight: '-1px',
                                                boxShadow:
                                                    '0 2px 8px rgba(0, 0, 0, 0.4)',
                                            }}
                                            className="icon fas fa-home"
                                        >
                                            3
                                        </i>
                                        <div className="details">
                                            <span className="title">
                                                Choose Your Sections
                                            </span>
                                        </div>
                                        <p>
                                            Click 'Get Alerts' for full
                                            sections. You can select unlimited
                                            sections per course.
                                        </p>
                                        <div className="infogrid-2">
                                            <img
                                                className="how-to-png"
                                                src={courseSelectPng}
                                                alt="home-png"
                                                onClick={() =>
                                                    openImageModal(
                                                        courseSelectPng
                                                    )
                                                }
                                            />
                                        </div>
                                    </section>
                                </div>
                                <div className="row row-2">
                                    <section>
                                        <i
                                            style={{
                                                boxShadow:
                                                    '0 2px 8px rgba(0, 0, 0, 0.4)',
                                            }}
                                            className="icon fas fa-map-marker-alt"
                                        >
                                            4
                                        </i>
                                        <div className="details">
                                            <span className="title">
                                                Fill Out Your Info
                                            </span>
                                        </div>
                                        <p>
                                            Fill out your contact information
                                            and expect a confirmation email
                                            shortly.
                                        </p>
                                        <div className="infogrid-2">
                                            <img
                                                className="how-to-png"
                                                src={modalPng}
                                                alt="home-png"
                                                onClick={() =>
                                                    openImageModal(modalPng)
                                                }
                                            />
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isImageModalOpen && (
                        <Modal
                            open={isImageModalOpen}
                            onClose={closeImageModal}
                        >
                            <div
                                className="image-modal-overlay"
                                onClick={closeImageModal}
                            >
                                <div
                                    className="image-modal-content"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <div
                                        className="closepng-button"
                                        onClick={closeImageModal}
                                    >
                                        <IoClose />
                                    </div>
                                    {selectedImage && (
                                        <img src={selectedImage} alt="" />
                                    )}
                                </div>
                            </div>
                        </Modal>
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Home;