import React from 'react';
import './FooterStyle.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-columns">
                    <div className="footer-column">
                        <h3>Navigate</h3>
                        <div>
                            <p>
                                <a className="footer-link" href="/">
                                    Home
                                </a>
                            </p>
                            <p>
                                <a className="footer-link" href="/about">
                                    About
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="footer-column">
                        <h3>Universities</h3>
                        <div>
                            <p>
                                <a className="footer-link" href="/waterloo">
                                    Waterloo
                                </a>
                            </p>
                            <p>
                                <a className="footer-link" href="/western">
                                    Western
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="footer-column">
                        <h3>Contact Us</h3>
                        <div>
                            <p>
                                <a
                                    className="footer-link"
                                    href="mailto:support@courseclutch.com"
                                >
                                    Email
                                </a>
                            </p>
                        </div>
                        <div>
                            <p>
                                <a
                                    className="footer-link"
                                    href="https://www.linkedin.com/company/course-clutch/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    LinkedIn
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="footer-column">
                        <h3>Extra</h3>
                        <div>
                            <p>
                                <a
                                    className="footer-link"
                                    href="/about#team-section"
                                >
                                    Our Team
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; Course Clutch. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
