import React, { useState } from 'react';
import './AccordionStyle.css';
import { IoIosArrowDropdownCircle } from 'react-icons/io';

interface CustomAccordionProps {
    title: string;
    children: React.ReactNode;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({
    title,
    children,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordion ${isOpen ? 'active' : ''}`}>
            <div
                className={`accordion-header ${isOpen ? 'active' : ''}`}
                onClick={toggleAccordion}
            >
                <p>{title}</p>

                <span
                    className={`material-symbols-outlined ${isOpen ? 'active' : ''}`}
                >
                    <IoIosArrowDropdownCircle />
                </span>
            </div>

            {isOpen && (
                <div className="accordion-content">
                    <p>{children}</p>
                </div>
            )}
        </div>
    );
};

export default CustomAccordion;
