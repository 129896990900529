import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../Assets/NavBar/NavBar';
import './WaterlooStyle.css';
import SearchBar from '../Assets/SearchBar/SearchBar';
import bothLogos from '../../images/UniversityLogos/CourseClutchWaterloo.png';
import waterlooAllCourseList from './waterlooAllCourseList.json';
import Sections from '../Assets/Sections/Waterloo/Sections';
import Footer from '../Assets/Footer/Footer';
import axios from 'axios';
import { ring } from 'ldrs';
import EnvContext from '../ContextProviders/EnvContext';

ring.register();

interface CourseSection {
    classNumber: number;
    termCode: string;
    courseId: string;
    courseComponent: string;
    classSection: number;
    courseName: string;
    name: string;
    email: string;
    phone: string;
    maxEnrollmentCapacity: number;
    enrolledStudents: number;
    scheduleData: {
        scheduleStartDate: string;
        scheduleEndDate: string;
        classMeetingStartTime: string;
        classMeetingEndTime: string;
        classMeetingWeekPatternCode: string;
    }[];
}

const gradient = ['#bb2236', '#eaab00'];

const Waterloo: React.FC = () => {
    const [clickedCourse, setClickedCourse] = useState<{
        termCode: number;
        subject: string;
        catalogNumber: string;
    } | null>(null);
    const [courseName, setCourseName] = useState<string>('');
    const [waterlooCourseSections, setWaterlooCourseSections] = useState<
        CourseSection[]
    >([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [termCode, setTermCode] = useState<number>(0);
    const [sentTerm, setSentTerm] = useState<number>(0);
    const hostname = useContext(EnvContext);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    }, []);

    const getTermCode = (): number => {
        let termCode: number = 1000;
        const now = new Date();
        const currentYear = now.getFullYear() % 100;
        const currentMonth = now.getMonth() + 1; // Adding 1 because months are zero-indexed

        termCode += currentYear * 10;

        if (1 <= currentMonth && currentMonth < 5) {
            termCode += 1;
        } else if (5 <= currentMonth && currentMonth < 9) {
            termCode += 5;
        } else {
            termCode += 9;
        }

        return termCode;
    };

    const decodeTermCode = (termCode: number): string => {
        // Extracting year and season from the term code
        const year = Math.floor(termCode / 10);
        const seasonCode = termCode % 10;
        // Determining the season
        let season: string;
        switch (seasonCode) {
            case 1:
                season = 'Winter';
                break;
            case 5:
                season = 'Spring';
                break;
            case 9:
                season = 'Fall';
                break;
            default:
                season = 'Unknown';
        }
        // Combining year and season to form the description
        const description = `${season} 20${year - 100}`;
        return description;
    };

    // Utility function to format the course code for display
    const formatCourseCode = (course: string): string => {
        const [code, title] = course.split(':').map((part) => part.trim());
        const formattedCode = code.replace(/([A-Za-z]+)(\d+)/, '$1 $2');
        return `${formattedCode}: ${title}`;
    };

    const encodeCourseCode = (formattedCourse: string): string => {
        const [formattedCode, title] = formattedCourse
            .split(':')
            .map((part) => part.trim());
        const originalCode = formattedCode.replace(/([A-Za-z]+) (\d+)/, '$1$2');
        return `${originalCode}: ${title}`;
    };

    useEffect(() => {
        const newTermCode = getTermCode();
        setTermCode(newTermCode);
        setSentTerm(newTermCode);

        return () => {
            setWaterlooCourseSections([]);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (clickedCourse) {
                    setLoading(true);
                    const response = await axios.post(
                        `${hostname}/api/waterloo/specific-course`,
                        clickedCourse
                    );
                    setWaterlooCourseSections(response.data.data);
                    setError(null); // Reset error if data fetched successfully
                    console.log(response, 'response');
                    console.log(response.data);
                    console.log(response.data.data);
                    // Add a delay before hiding the loading spinner
                    setTimeout(() => {
                        setLoading(false);
                    }, 500); // 0.5 seconds delay
                }
            } catch (error) {
                console.log(error);
                setError('Error fetching data');
                setWaterlooCourseSections([]); // Clear course data on error
                setLoading(false); // Ensure loading spinner is hidden on error
            }
        };

        fetchData();

        // Cleanup function to clear course data when unmounting or when changing courses
        return () => {
            setWaterlooCourseSections([]);
        };
    }, [clickedCourse, hostname]);

    const handleItemClick = (item: string) => {
        const originalItem = encodeCourseCode(item);
        const [codeAndNumber] = originalItem
            .split(':')
            .map((part) => part.trim());
        const matches = codeAndNumber.match(/([A-Za-z]+)(\d+[A-Za-z]*)/);
        if (matches) {
            const [, subject, catalogNumber] = matches;
            setClickedCourse({ termCode: sentTerm, subject, catalogNumber });
            setCourseName(originalItem); // store the original course name to be used as prop
        } else {
            setError('Invalid format for course');
        }
    };

    const handleRadioClick = (value: number) => {
        if (clickedCourse) {
            setClickedCourse({
                ...clickedCourse,
                termCode: value,
            });
        }
        setSentTerm(value);
    };
    return (
        <div>
            <div>
                <NavBar gradientColors={gradient} />
                <div className="watTitleContainer">
                    <div className="watTitle">
                        <div className="watLogo">
                            <img src={bothLogos} alt="University of Waterloo" />
                        </div>
                    </div>
                    <div className="radio-buttons">
                        <input
                            type="radio"
                            value={termCode}
                            name="term"
                            onClick={() => handleRadioClick(termCode)}
                            defaultChecked
                        />{' '}
                        <span className="radio-text">
                            {decodeTermCode(termCode)}
                        </span>
                        <input
                            type="radio"
                            value={
                                termCode % 10 === 9
                                    ? termCode + 2
                                    : termCode + 4
                            }
                            name="term"
                            onClick={() =>
                                handleRadioClick(
                                    termCode % 10 === 9
                                        ? termCode + 2
                                        : termCode + 4
                                )
                            }
                        />{' '}
                        <span className="radio-text">
                            {decodeTermCode(
                                termCode % 10 === 9
                                    ? termCode + 2
                                    : termCode + 4
                            )}
                        </span>
                    </div>
                    <div className="searchBarContainer">
                        <SearchBar
                            words={waterlooAllCourseList.map(formatCourseCode)} // Format codes for display
                            onItemClick={handleItemClick}
                        />
                    </div>
                </div>
                {loading && (
                    <div className="loading-container">
                        <l-ring
                            size="40"
                            stroke="4"
                            bg-opacity="0"
                            speed="2"
                            color="white"
                        ></l-ring>
                    </div>
                )}
                {/* {error ? (
                    <div className="error-container">
                        Error: {error || 'unable to find course info'}
                    </div>
                ) : (
                    <Sections
                        sectionsList={waterlooCourseSections}
                        courseName={courseName}
                    />
                )} */}
                {!error && waterlooCourseSections ? (
                    <Sections
                        sectionsList={waterlooCourseSections}
                        courseName={courseName}
                    />
                ) : (
                    <div className="error-container">
                        Error: {error ? error : 'unable to find course info'}
                    </div>
                )}
            </div>
            {!(waterlooCourseSections && waterlooCourseSections.length > 0) && (
                <div style={{ height: '300px' }}></div>
            )}
            <Footer />
        </div>
    );
};

export default Waterloo;
