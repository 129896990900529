import React, { useEffect } from 'react';
import NavBar from '../Assets/NavBar/NavBar';
import Footer from '../Assets/Footer/Footer';
import './AboutStyling.css';
import { IoLogoLinkedin } from 'react-icons/io5';
import Accordion from '../Assets/Accordian/Accordion';
import LogoBlack from '../../images/Logos/LogoBlack.png';

const About = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const yOffset = -80; // Adjust this value as needed
                const yPosition =
                    element.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset;
                window.scrollTo({ top: yPosition, behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div>
            <NavBar gradientColors={['#103783', '#4286f4']} />
            <div className="about-container">
                <div className="faq-content">
                    <h1 style={{ marginBottom: '30px' }}>
                        Frequently Asked Questions
                    </h1>
                    <Accordion title="Is Course Clutch a guarantee that I will get a seat in my requested course?">
                        <p>
                            No, Course Clutch is not a guarantee that you will
                            get a seat in your requested course. While we strive
                            to provide accurate and timely notifications about
                            course availability, we cannot guarantee admission
                            into any specific course.
                        </p>
                    </Accordion>
                    <Accordion title="Is Course Clutch affiliated with my university?">
                        <p>
                            No, Course Clutch is not affiliated with any
                            specific university. It's an independent platform
                            designed to simplify course enrollment for students.
                        </p>
                    </Accordion>
                    <Accordion title="Why isn't Course Clutch free?">
                        <p>
                            We understand that it might seem unexpected for a
                            service like ours to come with a cost. But behind
                            the scenes, there are ongoing expenses to keep our
                            platform running smoothly. From maintaining the
                            technical infrastructure to covering development and
                            upkeep, there's a lot that goes into ensuring Course
                            Clutch is always there when you need it.
                        </p>
                    </Accordion>
                    <Accordion title="How secure is Course Clutch, and is my payment information safe?">
                        <p>
                            Course Clutch prioritizes security. Your payment
                            details are handled exclusively by our payment
                            processor, Stripe. Course Clutch never has access to
                            this information. We only receive updates from
                            Stripe regarding your payment status.
                        </p>
                    </Accordion>
                    <Accordion title="How does Course Clutch obtain its data?">
                        <p>
                            Course Clutch relies on data provided by the
                            university. However, it cannot guarantee accuracy in
                            cases where the data is stale or where the
                            university fails to update it correctly or in a
                            timely manner. Course Clutch is responsible for
                            reading the availability data as presented by the
                            university and notifying users accordingly.
                        </p>
                    </Accordion>
                    <Accordion title=" I received a notification, but when I checked, there were no available spots or I couldn't enroll. Why did this happen?">
                        <p>
                            Sometimes, despite receiving a notification, spots
                            may have already been taken by others who registered
                            before you. Don't worry, as opportunities arise
                            frequently, and missing out on one spot doesn't mean
                            you won't find another soon. Please note that if you
                            miss out on a spot, you'll need to resubscribe to
                            continue receiving alerts.
                        </p>
                    </Accordion>
                    <Accordion title="How does Course Clutch differ from university waitlists?">
                        <p>
                            Many universities may not offer waitlists for all
                            classes. Additionally, for classes that do have
                            waitlists, they often reach full capacity quickly,
                            making it challenging to even join the waitlist.
                            Course Clutch addresses these challenges by
                            providing assistance in cases where there is no
                            waitlist available or where the waitlist is already
                            full. Whether the class lacks a waitlist or its
                            waitlist is full, Course Clutch helps you secure a
                            spot in the class or join its waitlist.
                        </p>
                    </Accordion>
                    <Accordion title="Can Course Clutch account for restricted or reserved seats?">
                        <p>
                            Course Clutch cannot account for any restricted or
                            reserved seats that users may or may not be eligible
                            for.
                        </p>
                    </Accordion>
                    <Accordion title="When will Course Clutch support my university?">
                        <p>
                            We're continually expanding our support to include
                            more universities. While we can't provide specific
                            timelines, we're actively working to broaden our
                            coverage. Keep an eye on our announcements and
                            updates for any news regarding support for your
                            university.
                        </p>
                    </Accordion>
                    <Accordion title="What happens if Course Clutch halts operations?">
                        <p>
                            Course Clutch reserves the right to halt operations
                            at any time if deemed necessary. Existing
                            transactions cannot be refunded.
                        </p>
                    </Accordion>
                </div>
                <div className="contact-content">
                    <h1 style={{ marginBottom: '30px' }}>Contact Us</h1>
                    <p
                        style={{
                            maxWidth: '600px',
                            margin: '0 auto 20px',
                            textAlign: 'center',
                        }}
                    >
                        If you still have any questions, feel free to reach out
                        to us at{' '}
                        <a
                            href="mailto:support@courseclutch.com"
                            className="team-link"
                        >
                            support@courseclutch.com
                        </a>
                        . We're here to help!
                    </p>
                </div>

                <div className="team-content" id="team-section">
                    <img
                        className="team-logo"
                        src={LogoBlack}
                        alt="Logo Black"
                    />
                    <h1 style={{ marginBottom: '20px' }}>
                        The Course Clutch Team
                    </h1>
                    <div className="team-members">
                        <div className="team-member">
                            <h2>Milan Pattni</h2>
                            <p
                                style={{
                                    marginTop: '-15px',
                                    paddingBottom: '15px',
                                }}
                            >
                                Co-Founder
                            </p>
                            <p>University of Waterloo</p>
                            <p
                                style={{
                                    paddingBottom: '20px',
                                }}
                            >
                                Mechatronics Engineering
                            </p>
                            <p>
                                <a
                                    className="team-link"
                                    href="https://www.linkedin.com/in/pattni/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IoLogoLinkedin className="linkedin-icon" />
                                </a>
                            </p>
                        </div>
                        <div className="team-member">
                            <h2>Ziven Rokhit</h2>
                            <p
                                style={{
                                    marginTop: '-15px',
                                    paddingBottom: '15px',
                                }}
                            >
                                Co-Founder
                            </p>
                            <p>University of Waterloo</p>
                            <p
                                style={{
                                    paddingBottom: '20px',
                                }}
                            >
                                Statistics & Computer Science
                            </p>
                            <a
                                className="team-link"
                                href="https://www.linkedin.com/in/zivenrokhit/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <IoLogoLinkedin className="linkedin-icon" />
                            </a>
                        </div>
                        <div className="team-member">
                            <h2>Aman Zaveri</h2>
                            <p
                                style={{
                                    marginTop: '-15px',
                                    paddingBottom: '15px',
                                }}
                            >
                                Software Engineer
                            </p>
                            <p>University of Waterloo</p>
                            <p
                                style={{
                                    paddingBottom: '20px',
                                }}
                            >
                                Mechatronics Engineering
                            </p>
                            <a
                                className="team-link"
                                href="https://www.linkedin.com/in/aman-zaveri/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <IoLogoLinkedin className="linkedin-icon" />
                            </a>
                        </div>
                        <div className="team-member">
                            <h2>Larry Chen</h2>
                            <p
                                style={{
                                    marginTop: '-15px',
                                    paddingBottom: '15px',
                                }}
                            >
                                Software Engineer
                            </p>
                            <p>University of Waterloo</p>
                            <p
                                style={{
                                    paddingBottom: '20px',
                                }}
                            >
                                Computer Science
                            </p>
                            <a
                                className="team-link"
                                href="https://www.linkedin.com/in/-larrychen/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <IoLogoLinkedin className="linkedin-icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: '100px' }}></div>
            <Footer />
        </div>
    );
};

export default About;
