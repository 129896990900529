import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Waterloo from "./components/Waterloo";
import Western from "./components/Western";
import About from "./components/About";
import Success from "./components/Success";
import Cancel from "./components/Cancel";
import EnvContext from "./components/ContextProviders/EnvContext";
import Home from "./components/Home";

const App = () => {
  const hostname: string =
    (window.location.hostname !== "localhost"
      ? process.env.REACT_APP_PROD_HOSTNAME
      : process.env.REACT_APP_DEV_HOSTNAME) || "";

  return (
    <EnvContext.Provider value={hostname}>
      <Router>
        <Routes>
          <Route path="/waterloo" element={<Waterloo />} />
          <Route path="/western" element={<Western />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
      </Router>
    </EnvContext.Provider>
  );
};

export default App;
