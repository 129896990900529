import React from 'react';
import NavBar from '../Assets/NavBar/NavBar';
import Footer from '../Assets/Footer/Footer';
import { Link } from 'react-router-dom';
import './SuccessStyling.css';

const Success = () => {
    return (
        <div>
            <NavBar gradientColors={['#077f61', '#3aaf82']} />
            <div className="success-container">
                <div className="success-content">
                    <h2>Order Success!</h2>
                    <p>
                        Thank you! You will be recieving an email confirmation
                        shortly!
                    </p>
                    <Link to="/" className="success-return-home">
                        Return Home
                    </Link>{' '}
                </div>
            </div>
            <div style={{ height: '400px' }}></div>
            <Footer />
        </div>
    );
};

export default Success;
